import React, { useState, useEffect } from "react";
import "../static/css/AdminTeam.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchWithAuth } from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL;

const AdminTeam = () => {
  const [name, setName] = useState("");
  const [role, setrole] = useState("");
  const [short_description, setshort_description] = useState("");
  const [description, setdescription] = useState("");
  const [image, setImage] = useState(null);
  const [specializations, setSpecializations] = useState([""]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [editingMember, setEditingMember] = useState(null);
  const [error, setError] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const response = await fetchWithAuth(`${API_URL}/team`);
      if (response.ok) {
        const data = await response.json();
        const processedData = data.map((member) => ({
          ...member,
          specializations:
            typeof member.specializations === "string"
              ? JSON.parse(member.specializations)
              : member.specializations || [],
        }));
        setTeamMembers(processedData);
        setError(null);
      }
    } catch (error) {
      console.error("Error fetching team members:", error);
      setError(
        "Failed to connect to the server. Please check if the backend is running."
      );
      setTeamMembers([]);
    }
  };

  const handleDelete = (member) => {
    setMemberToDelete(member);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await fetchWithAuth(
        `${API_URL}/team/delete/${memberToDelete.id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        fetchTeamMembers();
        toast.success("Team member deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting team member:", error);
      toast.error("Failed to delete team member");
    } finally {
      setIsDeleteModalOpen(false);
      setMemberToDelete(null);
    }
  };

  const handleEdit = (member) => {
    setEditingMember(member);
    setName(member.name);
    setrole(member.role);
    setshort_description(member.short_description);
    setdescription(member.description);
    setSpecializations(member.specializations);
  };

  const addSpecialization = () => {
    setSpecializations([...specializations, ""]);
  };

  const handleSpecializationChange = (index, value) => {
    const updatedSpecializations = [...specializations];
    updatedSpecializations[index] = value;
    setSpecializations(updatedSpecializations);
  };

  const removeSpecialization = (index) => {
    if (index === 0 || specializations.length <= 1) return;
    const updatedSpecializations = specializations.filter(
      (_, i) => i !== index
    );
    setSpecializations(updatedSpecializations);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!name || !role || !short_description || !description) {
        toast.error("Please fill in all required fields");
        return;
      }

      if (!editingMember && !image) {
        toast.error("Please upload an image for the new team member");
        return;
      }

      const formData = new FormData();
      formData.append("name", name);
      formData.append("role", role);
      formData.append("short_description", short_description);
      formData.append("description", description);
      formData.append(
        "specializations",
        JSON.stringify(specializations.filter((spec) => spec.trim() !== ""))
      );

      if (image) {
        formData.append("image", image, image.name);
      }

      const url = editingMember
        ? `${API_URL}/team/update/${editingMember.id}`
        : `${API_URL}/team/add`;
      const method = editingMember ? "PUT" : "POST";

      const response = await fetchWithAuth(url, {
        method,
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to submit form");
      }

      await response.json();
      toast.success(
        editingMember
          ? "Team member updated successfully!"
          : "Team member added successfully!"
      );

      setName("");
      setrole("");
      setshort_description("");
      setdescription("");
      setImage(null);
      setSpecializations([""]);
      setEditingMember(null);
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) fileInput.value = "";

      fetchTeamMembers();
    } catch (error) {
      toast.error(error.message || "Error submitting form.");
    }
  };

  const closeModal = () => {
    setEditingMember(null);
    setName("");
    setrole("");
    setshort_description("");
    setdescription("");
    setImage(null);
    setSpecializations([""]);
  };

  return (
    <>
      <div className="team-page">
        {error && (
          <div
            className="error-message"
            style={{
              color: "red",
              padding: "1rem",
              textAlign: "center",
              backgroundColor: "#ffebee",
              margin: "1rem",
            }}
          >
            {error}
          </div>
        )}
        <div className="admin-team">
          <div className="admin-team__container">
            {!editingMember ? (
              <form className="admin-team__add-form">
                <h2>Add Team Member</h2>
                <div className="admin-team__form-group">
                  <label htmlFor="name">Team Member Name</label>
                  <input
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="admin-team__input"
                  />
                </div>

                <div className="admin-team__form-group">
                  <label htmlFor="role">Role</label>
                  <input
                    id="role"
                    type="text"
                    value={role}
                    onChange={(e) => setrole(e.target.value)}
                    className="admin-team__input"
                  />
                </div>

                <div className="admin-team__form-group">
                  <label htmlFor="short_description">short_description</label>
                  <input
                    id="short_description"
                    type="text"
                    value={short_description}
                    onChange={(e) => setshort_description(e.target.value)}
                    className="admin-team__input"
                  />
                </div>

                <div className="admin-team__form-group">
                  <label htmlFor="description">description</label>
                  <input
                    id="description"
                    type="text"
                    value={description}
                    onChange={(e) => setdescription(e.target.value)}
                    className="admin-team__input"
                  />
                </div>

                <div className="admin-team__form-group">
                  <label>Specialization</label>
                  {specializations.map((specialization, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="text"
                        value={specialization}
                        onChange={(e) =>
                          handleSpecializationChange(index, e.target.value)
                        }
                        placeholder={`Specialization ${index + 1}`}
                        className="admin-team__input"
                      />
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={() => removeSpecialization(index)}
                          className="admin-team__delete-btn"
                          style={{ padding: "5px 10px" }}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addSpecialization}
                    className="admin-team__add-btn"
                  >
                    Add Another Specialization
                  </button>
                </div>

                <div className="admin-team__form-group">
                  <label htmlFor="TeamMember">Team Member Image</label>
                  <div className="admin-team__file-upload">
                    <input
                      type="file"
                      id="thumbnail"
                      onChange={(e) => setImage(e.target.files[0])}
                      className="admin-team__file-input"
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="thumbnail"
                      className="admin-team__upload-placeholder"
                    >
                      <i className="admin-team__upload-icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.66667 24C1.93333 24 1.30556 23.7389 0.783333 23.2167C0.261111 22.6944 0 22.0667 0 21.3333V2.66667C0 1.93333 0.261111 1.30556 0.783333 0.783333C1.30556 0.261111 1.93333 0 2.66667 0H21.3333C22.0667 0 22.6944 0.261111 23.2167 0.783333C23.7389 1.30556 24 1.93333 24 2.66667V21.3333C24 22.0667 23.7389 22.6944 23.2167 23.2167C22.6944 23.7389 22.0667 24 21.3333 24H2.66667ZM2.66667 21.3333H21.3333V2.66667H2.66667V21.3333ZM4 18.6667H20L15 12L11 17.3333L8 13.3333L4 18.6667Z"
                            fill="#828282"
                          />
                        </svg>
                      </i>
                      <span>Click to choose a file or drag it here</span>
                      {image && (
                        <span className="admin-team__selected-files">
                          {image.name}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
                <button
                  className="admin-team__submit-btn"
                  onClick={handleSubmit}
                >
                  Add Member
                </button>
              </form>
            ) : (
              <div className="team-modal">
                <div className="team-modal__content">
                  <h2 className="team-modal__title">Edit Team Member</h2>
                  <form className="admin-team__edit-form">
                    <div className="admin-team__form-group">
                      <label htmlFor="name">Team Member Name</label>
                      <input
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="admin-team__input"
                      />
                    </div>

                    <div className="admin-team__form-group">
                      <label htmlFor="role">Role</label>
                      <input
                        id="role"
                        type="text"
                        value={role}
                        onChange={(e) => setrole(e.target.value)}
                        className="admin-team__input"
                      />
                    </div>

                    <div className="admin-team__form-group">
                      <label htmlFor="short_description">
                        short_description
                      </label>
                      <input
                        id="short_description"
                        type="text"
                        value={short_description}
                        onChange={(e) => setshort_description(e.target.value)}
                        className="admin-team__input"
                      />
                    </div>

                    <div className="admin-team__form-group">
                      <label htmlFor="description">description</label>
                      <input
                        id="description"
                        type="text"
                        value={description}
                        onChange={(e) => setdescription(e.target.value)}
                        className="admin-team__input"
                      />
                    </div>

                    <div className="admin-team__form-group">
                      <label>Specialization</label>
                      {specializations.map((specialization, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="text"
                            value={specialization}
                            onChange={(e) =>
                              handleSpecializationChange(index, e.target.value)
                            }
                            placeholder={`Specialization ${index + 1}`}
                            className="admin-team__input"
                          />
                          {index > 0 && (
                            <button
                              type="button"
                              onClick={() => removeSpecialization(index)}
                              className="admin-team__delete-btn"
                              style={{ padding: "5px 10px" }}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={addSpecialization}
                        className="admin-team__add-btn"
                      >
                        Add Another Specialization
                      </button>
                    </div>

                    <div className="admin-team__form-group">
                      <label htmlFor="TeamMember">Team Member Image</label>
                      <div className="admin-team__file-upload">
                        <input
                          type="file"
                          id="thumbnail"
                          onChange={(e) => setImage(e.target.files[0])}
                          className="admin-team__file-input"
                          style={{ display: "none" }}
                        />
                        <label
                          htmlFor="thumbnail"
                          className="admin-team__upload-placeholder"
                        >
                          <i className="admin-team__upload-icon">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.66667 24C1.93333 24 1.30556 23.7389 0.783333 23.2167C0.261111 22.6944 0 22.0667 0 21.3333V2.66667C0 1.93333 0.261111 1.30556 0.783333 0.783333C1.30556 0.261111 1.93333 0 2.66667 0H21.3333C22.0667 0 22.6944 0.261111 23.2167 0.783333C23.7389 1.30556 24 1.93333 24 2.66667V21.3333C24 22.0667 23.7389 22.6944 23.2167 23.2167C22.6944 23.7389 22.0667 24 21.3333 24H2.66667ZM2.66667 21.3333H21.3333V2.66667H2.66667V21.3333ZM4 18.6667H20L15 12L11 17.3333L8 13.3333L4 18.6667Z"
                                fill="#828282"
                              />
                            </svg>
                          </i>
                          <span>Click to choose a file or drag it here</span>
                          {image && (
                            <span className="admin-team__selected-files">
                              {image.name}
                            </span>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="team-modal__buttons">
                      <button
                        className="team-modal__submit-btn"
                        onClick={handleSubmit}
                        type="button"
                      >
                        Update
                      </button>
                      <button
                        className="team-modal__cancel-btn"
                        onClick={closeModal}
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            <div className="admin-team__members-list">
              <h2>Team Members</h2>
              <div className="admin-team__grid">
                {teamMembers.map((member) => (
                  <div key={member.id} className="admin-team__member-card">
                    <img
                      src={`${API_URL}${member.image}`}
                      alt={member.name}
                      className="admin-team__member-image"
                    />
                    <div className="admin-team__member-info">
                      <h3>{member.name}</h3>
                      <p>{member.role}</p>
                      <p>{member.short_description}</p>
                      <div className="admin-team__member-specializations">
                        {Array.isArray(member.specializations) &&
                          member.specializations.map((spec, index) => (
                            <span
                              key={index}
                              className="admin-team__specialization-tag"
                            >
                              {spec}
                            </span>
                          ))}
                      </div>
                      <div className="admin-team__member-actions">
                        <button
                          onClick={() => handleEdit(member)}
                          className="admin-team__edit-btn"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(member)}
                          className="admin-team__delete-btn"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isDeleteModalOpen && (
        <div className="team-modal">
          <div className="team-modal__content" style={{ maxWidth: "400px" }}>
            <h2 className="team-modal__title">Confirm Deletion</h2>
            <p style={{ textAlign: "center", marginBottom: "20px" }}>
              Are you sure you want to delete {memberToDelete?.name}? This
              action cannot be undone.
            </p>
            <div className="team-modal__buttons">
              <button
                className="admin-team__button admin-team__delete-btn"
                onClick={confirmDelete}
              >
                Delete
              </button>
              <button
                className="admin-team__button"
                onClick={() => setIsDeleteModalOpen(false)}
                style={{ background: "#e0e0e0" }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};
export default AdminTeam;
