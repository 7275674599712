import React, { useState, useEffect, useCallback } from "react";
import "../static/css/AdminGallery.css";
import axios from "axios";
import Select from "react-select";
import { fetchWithAuth } from "../utils/api";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const AdminGallery = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [activeTab, setActiveTab] = useState("Anniversary");
  const [file, setFile] = useState(null);
  const [galleries, setGalleries] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "",
  });

  const fetchGalleries = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetchWithAuth(
        `${API_BASE_URL}/gallery/${activeTab.toLowerCase()}`
      );
      setGalleries(response.data || []);
    } catch (error) {
      setError("Error fetching galleries. Please try again.");
      setGalleries([]);
    } finally {
      setLoading(false);
    }
  }, [activeTab]);

  useEffect(() => {
    fetchGalleries();
  }, [fetchGalleries]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${API_BASE_URL}/gallery/${activeTab.toLowerCase()}/${id}`
      );
      fetchGalleries();
    } catch (error) {
      showNotification("Error deleting item", "error");
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();

      if (activeTab === "Anniversary") {
        if (editItem.editingYear) {
          formData.append("year", title);
          formData.append("oldYear", editItem.year);

          const endpoint = `${API_BASE_URL}/gallery/${activeTab.toLowerCase()}/${
            editItem.year
          }`;
          await axios.put(endpoint, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        } else {
          if (file && file.length > 0) {
            formData.append("year", editItem.year);
            for (let i = 0; i < file.length; i++) {
              formData.append("images", file[i]);
            }

            const endpoint = `${API_BASE_URL}/gallery/anniversary`;
            await axios.post(endpoint, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
          } else {
            throw new Error("No files selected");
          }
        }
      } else {
        formData.append("title", title);
        formData.append("content", content);
        if (file && file.length > 0) {
          for (let i = 0; i < file.length; i++) {
            formData.append("images", file[i]);
          }
        }

        const endpoint = `${API_BASE_URL}/gallery/${activeTab.toLowerCase()}/${
          editItem.id
        }`;
        await axios.put(endpoint, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }

      showNotification("Successfully updated item", "success");
      setShowEditModal(false);
      fetchGalleries();
    } catch (error) {
      console.error("Update error details:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        endpoint: `${API_BASE_URL}/gallery/${activeTab.toLowerCase()}/${
          editItem.id || editItem.year
        }`,
        formData: file ? `${file.length} files selected` : "No files",
      });

      showNotification(
        error.response?.data?.message ||
          "Error updating item. Please try again.",
        "error"
      );
    }
  };

  const handleFileChange = (e) => {
    setFile(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("content", content);

      if (file) {
        file.forEach((f) => {
          formData.append("images", f);
        });
      }

      let endpoint = "";
      if (activeTab === "Anniversary") {
        formData.append("year", title);
        endpoint = `${API_BASE_URL}/gallery/anniversary`;
      } else if (activeTab === "Seminar") {
        endpoint = `${API_BASE_URL}/gallery/seminar`;
      } else if (activeTab === "Award") {
        endpoint = `${API_BASE_URL}/gallery/award`;
      }

      await axios.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      showNotification("Successfully added new item", "success");

      setTitle("");
      setContent("");
      setFile(null);
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) fileInput.value = "";

      fetchGalleries();
    } catch (error) {
      showNotification("Error adding item", "error");
    }
  };

  const handleDeleteImage = async (year, imagePath) => {
    try {
      await axios.delete(`${API_BASE_URL}/gallery/anniversary/${year}/image`, {
        data: { imagePath },
      });
      fetchGalleries();
    } catch (error) {
      showNotification("Error deleting image", "error");
    }
  };

  const closeModal = () => {
    setShowEditModal(false);
    setEditItem(null);
    setTitle("");
    setContent("");
    setFile(null);
  };

  const handleDeleteClick = (id, imagePath) => {
    setItemToDelete({ id, imagePath });
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (itemToDelete.imagePath) {
        await handleDeleteImage(itemToDelete.id, itemToDelete.imagePath);
        showNotification("Successfully deleted image", "success");
      } else {
        await handleDelete(itemToDelete.id);
        showNotification("Successfully deleted item", "success");
      }
      setShowDeleteModal(false);
      setItemToDelete(null);
    } catch (error) {
      showNotification("Error deleting item", "error");
    }
  };

  const showNotification = (message, type) => {
    setNotification({ show: true, message, type });
    setTimeout(() => {
      setNotification({ show: false, message: "", type: "" });
    }, 3000);
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: currentYear - 1999 }, (_, i) => ({
    value: currentYear - i,
    label: (currentYear - i).toString(),
  }));

  return (
    <>
      {notification.show && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
      <div className="Admincontrol">
        <div className="admincontrol-header">
          <div className="admincontrol-form">
            <div className="gallery-buttons">
              <button
                className={activeTab === "Anniversary" ? "active" : ""}
                onClick={() => setActiveTab("Anniversary")}
              >
                Anniversary
              </button>
              <button
                className={activeTab === "Seminar" ? "active" : ""}
                onClick={() => setActiveTab("Seminar")}
              >
                Seminar
              </button>
              <button
                className={activeTab === "Award" ? "active" : ""}
                onClick={() => setActiveTab("Award")}
              >
                Award
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <label htmlFor="title">
                {activeTab === "Anniversary"
                  ? "Year"
                  : activeTab === "Seminar"
                  ? "Seminar Name"
                  : "Award Name"}
              </label>
              {activeTab === "Anniversary" ? (
                <Select
                  options={yearOptions}
                  value={yearOptions.find(
                    (option) => option.value === parseInt(title)
                  )}
                  onChange={(selectedOption) =>
                    setTitle(selectedOption.value.toString())
                  }
                  placeholder="Select year"
                />
              ) : (
                <input
                  id="title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder={
                    activeTab === "Seminar"
                      ? "Enter seminar name"
                      : activeTab === "Award"
                      ? "Enter award name"
                      : "Enter year"
                  }
                />
              )}

              {activeTab !== "Anniversary" && (
                <>
                  <label htmlFor="content">
                    {activeTab === "Seminar"
                      ? "Seminar Description"
                      : "Award Description"}
                  </label>
                  <input
                    id="content"
                    type="text"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder={
                      activeTab === "Seminar"
                        ? "About Seminar"
                        : "About the Award"
                    }
                  />
                </>
              )}

              <label htmlFor="file">Upload Image</label>
              <div className="file-upload">
                <input
                  id="file"
                  type="file"
                  onChange={handleFileChange}
                  multiple
                />
                <span className="file-upload-placeholder">
                  <i className="upload-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.66667 24C1.93333 24 1.30556 23.7389 0.783333 23.2167C0.261111 22.6944 0 22.0667 0 21.3333V2.66667C0 1.93333 0.261111 1.30556 0.783333 0.783333C1.30556 0.261111 1.93333 0 2.66667 0H21.3333C22.0667 0 22.6944 0.261111 23.2167 0.783333C23.7389 1.30556 24 1.93333 24 2.66667V21.3333C24 22.0667 23.7389 22.6944 23.2167 23.2167C22.6944 23.7389 22.0667 24 21.3333 24H2.66667ZM2.66667 21.3333H21.3333V2.66667H2.66667V21.3333ZM4 18.6667H20L15 12L11 17.3333L8 13.3333L4 18.6667Z"
                        fill="#828282"
                      />
                    </svg>
                  </i>
                  {file ? `${file.length} files selected` : "Upload Images"}
                </span>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>

        <div className="gallery-display">
          <h2>{activeTab} Gallery</h2>
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div className="error-message">{error}</div>
          ) : (
            <div className="gallery-grid">
              {activeTab === "Anniversary" ? (
                Array.isArray(galleries) && galleries.length > 0 ? (
                  galleries.map((item) => (
                    <div
                      key={`anniversary-${item.year}`}
                      className="gallery-item anniversary-item"
                    >
                      <div className="year-heading-container">
                        <h3 className="year-heading">{item.year}</h3>
                        <div className="year-actions">
                          <button
                            className="edit-year-btn"
                            onClick={() => {
                              setEditItem({
                                year: item.year,
                                editingYear: true,
                              });
                              setTitle(item.year);
                              setShowEditModal(true);
                            }}
                          >
                            Edit Year
                          </button>
                          <button
                            className="add-image-btn"
                            onClick={() => {
                              setEditItem({
                                year: item.year,
                                editingYear: false,
                              });
                              setTitle(item.year);
                              setShowEditModal(true);
                            }}
                          >
                            Add Images
                          </button>
                        </div>
                      </div>
                      <div className="images-grid">
                        {Array.isArray(item.images) &&
                          item.images.map((imagePath, index) => (
                            <div
                              key={`anniversary-${item.year}-image-${index}`}
                              className="image-container"
                            >
                              <img
                                src={`${API_BASE_URL}${imagePath}`}
                                alt={`${item.year} - ${index + 1}`}
                              />
                              <div className="image-actions">
                                <button
                                  onClick={() =>
                                    handleDeleteClick(item.year, imagePath)
                                  }
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <div key="no-anniversary">No anniversary data available</div>
                )
              ) : Array.isArray(galleries) && galleries.length > 0 ? (
                galleries.map((item, index) => (
                  <div
                    key={
                      item.id
                        ? `${activeTab.toLowerCase()}-${item.id}`
                        : `${activeTab.toLowerCase()}-item-${index}`
                    }
                    className="gallery-item"
                  >
                    <div className="images-grid">
                      {Array.isArray(item.images) &&
                        item.images.map((imagePath, imgIndex) => (
                          <div
                            key={`${activeTab.toLowerCase()}-${
                              item.id || index
                            }-image-${imgIndex}`}
                            className="image-container"
                          >
                            <img
                              src={`${API_BASE_URL}${imagePath}`}
                              alt={`${item.title || `Item ${index + 1}`} - ${
                                imgIndex + 1
                              }`}
                            />
                            <div className="image-actions">
                              <button
                                onClick={() => {
                                  setEditItem({
                                    ...item,
                                    currentImage: imagePath,
                                  });
                                  setTitle(item.title || "");
                                  setContent(item.content || "");
                                  setShowEditModal(true);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteClick(item.id || index, imagePath)
                                }
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="gallery-item-details">
                      <h3>{item.title || `Item ${index + 1}`}</h3>
                      <p>{item.content || ""}</p>
                      <div className="gallery-item-actions">
                        <button
                          onClick={() => {
                            setEditItem(item);
                            setTitle(item.title || "");
                            setContent(item.content || "");
                            setShowEditModal(true);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDeleteClick(item.id)}
                          disabled={!item.id}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div key={`no-${activeTab.toLowerCase()}-data`}>
                  No {activeTab.toLowerCase()} data available
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {showEditModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="edit-modal" onClick={(e) => e.stopPropagation()}>
            <div className="edit-modal-header">
              <h2>
                {activeTab === "Anniversary"
                  ? editItem?.editingYear
                    ? "Edit Year"
                    : "Add Images"
                  : editItem?.currentImage
                  ? "Edit Image"
                  : "Add Image"}
              </h2>
            </div>
            <form onSubmit={handleEdit} className="edit-form">
              {(editItem?.editingYear || activeTab !== "Anniversary") && (
                <div className="edit-form-group">
                  <label className="edit-form-label">
                    {activeTab === "Anniversary" ? "Year" : `${activeTab} Name`}
                  </label>
                  {activeTab === "Anniversary" && editItem?.editingYear ? (
                    <Select
                      options={yearOptions}
                      value={yearOptions.find(
                        (option) => option.value === parseInt(title)
                      )}
                      onChange={(selectedOption) =>
                        setTitle(selectedOption.value.toString())
                      }
                      placeholder="Select year"
                    />
                  ) : (
                    <input
                      className="edit-form-input"
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  )}
                </div>
              )}

              {activeTab !== "Anniversary" && (
                <div className="edit-form-group">
                  <label className="edit-form-label">Description</label>
                  <textarea
                    className="edit-form-textarea"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </div>
              )}

              {!editItem?.editingYear && (
                <div className="edit-form-group">
                  <div className="edit-form-file-wrapper">
                    <input
                      className="edit-form-file-input"
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                      multiple
                    />
                    <div className="edit-form-file-label">
                      <span className="edit-form-file-icon">📁</span>
                      <span>Click to choose files or drag them here</span>
                      {file && (
                        <span className="selected-files-count">
                          {file.length} {file.length === 1 ? "file" : "files"}{" "}
                          selected
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="edit-form-actions">
                <button type="submit" className="edit-form-save">
                  {editItem?.editingYear
                    ? "Update Year"
                    : file
                    ? `Upload ${file.length} ${
                        file.length === 1 ? "Image" : "Images"
                      }`
                    : "Save Changes"}
                </button>
                <button
                  type="button"
                  className="edit-form-cancel"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div
          className="modal-overlay"
          onClick={() => setShowDeleteModal(false)}
        >
          <div className="delete-modal" onClick={(e) => e.stopPropagation()}>
            <div className="delete-modal-header">
              <h2>Confirm Delete</h2>
            </div>
            <div className="delete-modal-content">
              <p>
                Are you sure you want to delete this{" "}
                {itemToDelete?.imagePath ? "image" : "item"}?
              </p>
              <p className="delete-warning">This action cannot be undone.</p>
            </div>
            <div className="delete-modal-actions">
              <button
                className="delete-confirm-btn"
                onClick={handleDeleteConfirm}
              >
                Delete
              </button>
              <button
                className="delete-cancel-btn"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminGallery;
