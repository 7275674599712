import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../static/css/TextEditor.css";

const TextEditor = ({ content, setContent }) => {
  return (
    <div className="editor-container">
      <CKEditor
        editor={ClassicEditor}
        data={content}
        onChange={(event, editor) => {
          const data = editor.getData();
          setContent(data);
        }}
        config={{
          toolbar: [
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "undo",
            "redo"
          ],
          placeholder: "Write your article...",
        }}
      />
    </div>
  );
};

export default TextEditor;
