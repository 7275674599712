import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../static/img/logo.png";
import "../static/css/AdminNav.css";
import { useAuth } from "../context/AuthContext";
import { FaNewspaper, FaCalendarAlt, FaImages, FaUsers, FaUsersCog, FaSignOutAlt } from "react-icons/fa";

const AdminNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div className="admin-Nav">
      <div className="adminNav-title">
        <img src={logo} alt="SKP & Co" />
      </div>
      <div className="adminNav-button">
        <Link
          to="/adminpublications"
          className={location.pathname === "/adminpublications" ? "active" : ""}
        >
          <FaNewspaper className="nav-icon" /> Publications
        </Link>
        <Link
          to="/adminschedule"
          className={location.pathname === "/adminschedule" ? "active" : ""}
        >
          <FaCalendarAlt className="nav-icon" /> Scheduling
        </Link>
        <Link
          to="/admingallery"
          className={location.pathname === "/admingallery" ? "active" : ""}
        >
          <FaImages className="nav-icon" /> Gallery
        </Link>
        <Link
          to="/adminteam"
          className={location.pathname === "/adminteam" ? "active" : ""}
        >
          <FaUsers className="nav-icon" /> Team Members
        </Link>
        <Link
          to="/admincontrol"
          className={location.pathname === "/admincontrol" ? "active" : ""}
        >
          <FaUsersCog className="nav-icon" /> Admin Users
        </Link>
      </div>
      <div className="adminNav-logout">
        <button onClick={handleLogout}>
          <FaSignOutAlt className="nav-icon" /> Logout
        </button>
      </div>
    </div>
  );
};

export default AdminNav;
