import React, { useState, useEffect } from "react";
import { fetchWithAuth } from "../utils/api";
import "../static/css/AdminControl.css";

const API_URL = process.env.REACT_APP_API_URL;

const AdminControl = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetchWithAuth(`${API_URL}/users`);
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newAdmin = { username: name, emailid: email, password };

    try {
      const response = await fetchWithAuth(`${API_URL}/users`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newAdmin),
      });

      if (response.ok) {
        fetchUsers();
      } else {
        const errorText = await response.text();
        setErrorMessage(errorText);
      }
    } catch (error) {
      setErrorMessage("An error occurred while adding admin.");
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (!selectedUser) return;

    try {
      const response = await fetchWithAuth(
        `${API_URL}/users/${selectedUser.id}/password`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ oldPassword, newPassword }),
        }
      );

      if (response.ok) {
        setSuccessMessage("Password updated successfully");
        setOldPassword("");
        setNewPassword("");
      } else {
        const errorText = await response.text();
        setErrorMessage(errorText);
      }
    } catch (error) {
      setErrorMessage("An error occurred while updating password");
    }
  };

  return (
    <div className="Admincontrol">
      <div className="admincontrol-header">
        <div className="admincontrol-form">
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
            />
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
            <button type="submit">Add New Admin</button>
          </form>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
      <div className="users-list">
        <h2>Existing Users</h2>
        <div className="users-grid">
          {users.map((user) => (
            <div
              key={user.id}
              className="user-card"
              onClick={() => setSelectedUser(user)}
            >
              <h3>{user.username}</h3>
              <p>{user.emailid}</p>
            </div>
          ))}
        </div>
        {selectedUser && (
          <div className="password-change-form">
            <h3>Change Password for {selectedUser.username}</h3>
            <form onSubmit={handlePasswordChange}>
              <label htmlFor="oldPassword">Old Password</label>
              <input
                id="oldPassword"
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Enter old password"
              />
              <label htmlFor="newPassword">New Password</label>
              <input
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
              />
              <button type="submit">Update Password</button>
            </form>
          </div>
        )}
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default AdminControl;
