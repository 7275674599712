import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminNav from "./components/AdminNav.jsx";
import AdminLogin from "./components/AdminLogin.jsx";
import AdminControl from "./pages/AdminControl.jsx";
import AdminPublications from "./pages/AdminPublications.jsx";
import AdminSchedule from "./pages/AdminSchedule.jsx";
import AdminGallery from "./pages/AdminGallery.jsx";
import AdminTeam from "./pages/AdminTeam.jsx";
import { AuthProvider } from "./context/AuthContext.jsx";
import ProtectedRoute from "./components/ProtectedRoute.jsx";
import "./App.css";

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AdminLogin />} />
          <Route path="/login" element={<AdminLogin />} />

          <Route
            path="/admincontrol"
            element={
              <ProtectedRoute>
                <div className="admin-main">
                  <AdminNav />
                  <div className="admin-content">
                    <AdminControl />
                  </div>
                </div>
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminpublications"
            element={
              <ProtectedRoute>
                <div className="admin-main">
                  <AdminNav />
                  <div className="admin-content">
                    <AdminPublications />
                  </div>
                </div>
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminschedule"
            element={
              <ProtectedRoute>
                <div className="admin-main">
                  <AdminNav />
                  <div className="admin-content">
                    <AdminSchedule />
                  </div>
                </div>
              </ProtectedRoute>
            }
          />
          <Route
            path="/admingallery"
            element={
              <ProtectedRoute>
                <div className="admin-main">
                  <AdminNav />
                  <div className="admin-content">
                    <AdminGallery />
                  </div>
                </div>
              </ProtectedRoute>
            }
          />
          <Route
            path="/adminteam"
            element={
              <ProtectedRoute>
                <div className="admin-main">
                  <AdminNav />
                  <div className="admin-content">
                    <AdminTeam />
                  </div>
                </div>
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
