import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem("adminToken");
    const expiration = localStorage.getItem("tokenExpiration");

    if (storedToken && expiration) {
      if (new Date().getTime() > parseInt(expiration)) {
        localStorage.removeItem("adminToken");
        localStorage.removeItem("tokenExpiration");
        return null;
      }
    }
    return storedToken;
  });

  const login = (newToken) => {
    localStorage.setItem("adminToken", newToken);
    setToken(newToken);
  };

  const logout = () => {
    localStorage.removeItem("adminToken");
    localStorage.removeItem("tokenExpiration");
    setToken(null);
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const expiration = localStorage.getItem("tokenExpiration");
      if (expiration && new Date().getTime() > parseInt(expiration)) {
        logout();
      }
    };

    const interval = setInterval(checkTokenExpiration, 60000);

    checkTokenExpiration();

    return () => clearInterval(interval);
  }, []);

  const value = {
    token,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthContext;
