import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../static/css/AdminSchedule.css";
import { fetchWithAuth } from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL;

const AdminSchedule = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [event, setEvent] = useState("");
  const [description, setDescription] = useState("");
  const [schedules, setSchedules] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    try {
      const response = await fetchWithAuth(`${API_URL}/schedule`);
      if (response.ok) {
        const data = await response.json();
        setSchedules(data);
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  const handleEdit = (schedule) => {
    setEditingId(schedule.id);
    const adjustedDate = new Date(schedule.date);
    adjustedDate.setDate(adjustedDate.getDate());
    setSelectedDate(adjustedDate);
    setSelectedTime(new Date(`2000-01-01T${schedule.time}`));
    setEvent(schedule.event);
    setDescription(schedule.description);
    setShowEditModal(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const adjustedDate = new Date(selectedDate);
    adjustedDate.setDate(adjustedDate.getDate() + 1);

    const data = {
      date: adjustedDate.toISOString().split("T")[0],
      time: selectedTime ? selectedTime.toTimeString().split(" ")[0] : null,
      event,
      description,
    };

    try {
      const response = await fetchWithAuth(
        `${API_URL}/schedule/update/${editingId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        showTemporarySuccess("Event updated successfully!");
        fetchSchedules();
        closeEditModal();
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error updating event.");
    }
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setEditingId(null);
    setSelectedDate(null);
    setSelectedTime(null);
    setEvent("");
    setDescription("");
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await fetchWithAuth(
        `${API_URL}/schedule/delete/${deleteId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setShowDeleteModal(false);
        setDeleteId(null);
        showTemporarySuccess("Event deleted successfully!");
        fetchSchedules();
      }
    } catch (error) {
      console.error("Error deleting schedule:", error);
      alert("Error deleting event.");
    }
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteId(null);
  };

  const showTemporarySuccess = (message) => {
    setSuccessMessage(message);
    setShowSuccessModal(true);
    setTimeout(() => {
      setShowSuccessModal(false);
      setSuccessMessage("");
    }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const adjustedDate = new Date(selectedDate);
    adjustedDate.setDate(adjustedDate.getDate() + 1);

    const data = {
      date: adjustedDate.toISOString().split("T")[0],
      time: selectedTime ? selectedTime.toTimeString().split(" ")[0] : null,
      event,
      description,
    };

    try {
      const response = await fetchWithAuth(`${API_URL}/schedule/add`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        showTemporarySuccess("Event added successfully!");
        fetchSchedules();

        setSelectedDate(null);
        setSelectedTime(null);
        setEvent("");
        setDescription("");
        const dateInputs = document.querySelectorAll(
          ".react-datepicker__input-container input"
        );
        dateInputs.forEach((input) => (input.value = ""));
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting form.");
    }
  };

  const organizeSchedulesByMonth = () => {
    const organized = {};

    schedules.sort((a, b) => new Date(a.date) - new Date(b.date));

    schedules.forEach((schedule) => {
      const date = new Date(schedule.date);
      const monthYear = date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });

      if (!organized[monthYear]) {
        organized[monthYear] = [];
      }
      organized[monthYear].push(schedule);
    });

    return organized;
  };

  const submitButtonText = editingId ? "Update Event" : "Add Event";

  return (
    <div className="Admincontrol">
      <div className="schedule-form">
        <h2>Add New Event</h2>
        <form>
          <label htmlFor="date">Date</label>
          <div className="input-with-icon">
            <DatePicker
              selected={selectedDate}
              onChange={setSelectedDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select date"
              className="date-picker-input"
              calendarClassName="custom-calendar"
              wrapperClassName="date-picker-wrapper"
            />
          </div>
          <label htmlFor="time">Time</label>
          <div className="input-with-icon">
            <DatePicker
              selected={selectedTime}
              onChange={setSelectedTime}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              placeholderText="Select time"
              className="date-picker-input"
              calendarClassName="custom-time-picker"
              wrapperClassName="date-picker-wrapper"
            />
          </div>
          <label htmlFor="event">Event</label>
          <input
            type="text"
            id="event"
            value={event}
            placeholder="Enter event name"
            onChange={(e) => setEvent(e.target.value)}
          />
          <label htmlFor="description">Event Description</label>
          <textarea
            id="description"
            value={description}
            placeholder="Enter event description"
            onChange={(e) => setDescription(e.target.value)}
          />
          <button
            type="submit"
            onClick={handleSubmit}
            className={editingId ? "update-btn" : "submit-btn"}
          >
            {submitButtonText}
          </button>
          {editingId && (
            <button
              type="button"
              onClick={() => {
                setEditingId(null);
                setSelectedDate(null);
                setSelectedTime(null);
                setEvent("");
                setDescription("");
              }}
              className="cancel-btn"
            >
              Cancel Edit
            </button>
          )}
        </form>
      </div>

      <div className="schedule-list">
        <h2>Existing Events</h2>
        {Object.entries(organizeSchedulesByMonth()).map(
          ([monthYear, monthSchedules]) => (
            <div key={monthYear} className="month-section">
              <h3 className="month-header">{monthYear}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Event</th>
                    <th>Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {monthSchedules.map((schedule) => (
                    <tr
                      key={schedule.id}
                      className={editingId === schedule.id ? "editing" : ""}
                    >
                      <td>
                        {new Date(schedule.date).toLocaleDateString("default", {
                          day: "numeric",
                          month: "short",
                        })}
                      </td>
                      <td>
                        {new Date(
                          `2000-01-01T${schedule.time}`
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                      <td>{schedule.event}</td>
                      <td>{schedule.description}</td>
                      <td>
                        <button
                          onClick={() => handleEdit(schedule)}
                          className="edit-btn"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(schedule.id)}
                          className="delete-btn"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}
      </div>

      {showEditModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Edit Event</h2>
            <form onSubmit={handleEditSubmit}>
              <label htmlFor="edit-date">Date</label>
              <div className="input-with-icon">
                <DatePicker
                  selected={selectedDate}
                  onChange={setSelectedDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select date"
                  className="date-picker-input"
                  calendarClassName="custom-calendar"
                  wrapperClassName="date-picker-wrapper"
                />
              </div>

              <label htmlFor="edit-time">Time</label>
              <div className="input-with-icon">
                <DatePicker
                  selected={selectedTime}
                  onChange={setSelectedTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="Select time"
                  className="date-picker-input"
                  calendarClassName="custom-time-picker"
                  wrapperClassName="date-picker-wrapper"
                />
              </div>

              <label htmlFor="edit-event">Event</label>
              <input
                type="text"
                id="edit-event"
                value={event}
                onChange={(e) => setEvent(e.target.value)}
              />

              <label htmlFor="edit-description">Description</label>
              <textarea
                id="edit-description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />

              <div className="modal-buttons">
                <button type="submit" className="update-btn">
                  Update Event
                </button>
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={closeEditModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal-content delete-modal">
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this event?</p>
            <p>This action cannot be undone.</p>
            <div className="modal-buttons">
              <button onClick={closeDeleteModal} className="cancel-btn">
                Cancel
              </button>
              <button onClick={confirmDelete} className="delete-confirm-btn">
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <div className="success-modal">
          <div className="success-content">
            <div className="success-icon">✓</div>
            <p>{successMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSchedule;
