import React, { useState, useEffect } from "react";
import TextEditor from "../components/TextEditor.jsx";
import "../static/css/AdminPublications.css";
import { FaEdit, FaTrash } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const API_URL = process.env.REACT_APP_API_URL;

const AdminPublications = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [date, setDate] = useState("");
  const [publicationContent, setPublicationContent] = useState("");
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newCategoryImage, setNewCategoryImage] = useState(null);
  const [newCategoryDescription, setNewCategoryDescription] = useState("");
  const [publicationImage, setPublicationImage] = useState(null);
  const [publications, setPublications] = useState([]);
  const [editingPublication, setEditingPublication] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await fetch(`${API_URL}/categories`);
        if (!categoriesResponse.ok) {
          throw new Error(
            `Categories HTTP error! status: ${categoriesResponse.status}`
          );
        }
        const categoriesData = await categoriesResponse.json();
        setCategories(categoriesData);

        const publicationsResponse = await fetch(`${API_URL}/publications/all`);
        if (!publicationsResponse.ok) {
          throw new Error(
            `Publications HTTP error! status: ${publicationsResponse.status}`
          );
        }
        const publicationsData = await publicationsResponse.json();
        setPublications(publicationsData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setCategories([]);
        setPublications([]);
      }
    };

    fetchData();
  }, []);

  const handleAddCategory = async () => {
    if (newCategory) {
      const categoryData = {
        name: newCategory,
        description: newCategoryDescription,
        image: newCategoryImage,
      };

      try {
        const response = await fetch(`${API_URL}/categories/add`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(categoryData),
        });

        if (response.ok) {
          const result = await response.json();
          alert("Category added successfully!");
          console.log(result);

          setNewCategory("");
          setNewCategoryImage(null);
          setNewCategoryDescription("");
          setCategories([...categories, result]);
        } else {
          alert("Failed to add category.");
        }
      } catch (error) {
        console.error("Error adding category:", error);
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewCategoryImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePublicationImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPublicationImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    const newPublication = {
      title,
      description,
      date,
      category_id: categoryId,
      content: publicationContent,
      image_url: publicationImage,
    };

    try {
      const response = await fetch(`${API_URL}/publications/add`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newPublication),
      });

      if (response.ok) {
        await response.json();
        alert("Publication posted successfully!");

        setTitle("");
        setDescription("");
        setDate("");
        setCategoryId("");
        setPublicationContent("");
        setPublicationImage(null);
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) fileInput.value = "";
      } else {
        alert("Failed to post publication.");
      }
    } catch (error) {
      console.error("Error posting publication:", error);
    }
  };

  const handleDeletePublication = async (id) => {
    if (window.confirm("Are you sure you want to delete this publication?")) {
      try {
        const response = await fetch(`${API_URL}/publications/${id}`, {
          method: "DELETE",
        });
        if (response.ok) {
          setPublications(publications.filter((pub) => pub.id !== id));
          alert("Publication deleted successfully!");
        }
      } catch (error) {
        console.error("Error deleting publication:", error);
      }
    }
  };

  const handleDeleteCategory = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        const response = await fetch(`${API_URL}/categories/${id}`, {
          method: "DELETE",
        });
        if (response.ok) {
          setCategories(categories.filter((cat) => cat.id !== id));
          alert("Category deleted successfully!");
        }
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    }
  };

  const handleUpdatePublication = async (id) => {
    try {
      const response = await fetch(`${API_URL}/publications/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...editingPublication,
          image_url: editingPublication.image_url,
        }),
      });
      if (response.ok) {
        setPublications(
          publications.map((pub) => (pub.id === id ? editingPublication : pub))
        );
        setEditingPublication(null);
        alert("Publication updated successfully!");
      }
    } catch (error) {
      console.error("Error updating publication:", error);
    }
  };

  const handleUpdateCategory = async (id) => {
    try {
      const response = await fetch(`${API_URL}/categories/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...editingCategory,
          image: editingCategory.image,
        }),
      });
      if (response.ok) {
        setCategories(
          categories.map((cat) => (cat.id === id ? editingCategory : cat))
        );
        setEditingCategory(null);
        alert("Category updated successfully!");
      }
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleEditPublicationImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditingPublication({
          ...editingPublication,
          image_url: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditCategoryImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditingCategory({
          ...editingCategory,
          image: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="admin-container">
      <div className="pub-content">
        <input
          type="text"
          placeholder="Article Title"
          className="article-title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <textarea
          placeholder="Article Description"
          className="article-description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <select
          className="dropdown"
          value={categoryId}
          onChange={(e) => setCategoryId(e.target.value)}
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>

        <div className="editor">
          <TextEditor
            content={publicationContent}
            setContent={setPublicationContent}
          />
        </div>

        <div className="input-with-icon">
          <DatePicker
            selected={date ? new Date(date) : null}
            onChange={(date) =>
              setDate(date ? date.toISOString().split("T")[0] : "")
            }
            dateFormat="dd/MM/yyyy"
            placeholderText="Select date"
            className="date-picker-input"
            calendarClassName="custom-calendar"
            wrapperClassName="date-picker-wrapper"
          />
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={handlePublicationImageChange}
          className="publication-image-upload"
          id="publication-image"
        />
        {publicationImage && (
          <img
            src={publicationImage}
            alt="Publication Preview"
            className="publication-image-preview"
          />
        )}

        <button className="post-button" onClick={handleSubmit}>
          POST PUBLICATION
        </button>

        <div className="publications-list">
          <h3>Existing Publications</h3>
          {publications.map((pub) => (
            <div key={pub.id} className="list-item">
              {editingPublication?.id === pub.id ? (
                <div className="edit-form">
                  <input
                    value={editingPublication.title}
                    onChange={(e) =>
                      setEditingPublication({
                        ...editingPublication,
                        title: e.target.value,
                      })
                    }
                  />
                  <textarea
                    value={editingPublication.description}
                    onChange={(e) =>
                      setEditingPublication({
                        ...editingPublication,
                        description: e.target.value,
                      })
                    }
                  />
                  <select
                    value={editingPublication.category_id}
                    onChange={(e) =>
                      setEditingPublication({
                        ...editingPublication,
                        category_id: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  <div className="input-with-icon">
                    <DatePicker
                      selected={
                        editingPublication.date
                          ? new Date(editingPublication.date)
                          : null
                      }
                      onChange={(date) =>
                        setEditingPublication({
                          ...editingPublication,
                          date: date ? date.toISOString().split("T")[0] : "",
                        })
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="date-picker-input"
                      calendarClassName="custom-calendar"
                      wrapperClassName="date-picker-wrapper"
                    />
                  </div>
                  <div className="editor-container">
                    <TextEditor
                      content={editingPublication.content}
                      setContent={(content) =>
                        setEditingPublication({
                          ...editingPublication,
                          content,
                        })
                      }
                    />
                  </div>
                  <div className="image-edit-container">
                    {editingPublication.image_url && (
                      <img
                        src={editingPublication.image_url}
                        alt="Current"
                        className="edit-image-preview"
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleEditPublicationImage}
                      className="edit-image-upload"
                    />
                  </div>
                  <button onClick={() => handleUpdatePublication(pub.id)}>
                    Save
                  </button>
                  <button onClick={() => setEditingPublication(null)}>
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="item-content">
                  <h4>{pub.title}</h4>
                  <p className="pub-description">{pub.description}</p>
                  <div className="pub-details">
                    <p className="pub-category">
                      Category:{" "}
                      {
                        categories.find((cat) => cat.id === pub.category_id)
                          ?.name
                      }
                    </p>
                    <p className="pub-date">
                      Date: {new Date(pub.date).toLocaleDateString()}
                    </p>
                  </div>
                  <div
                    className="pub-content"
                    dangerouslySetInnerHTML={{ __html: pub.content }}
                  />
                  {pub.image_url && (
                    <img
                      src={pub.image_url}
                      alt={pub.title}
                      className="item-image"
                    />
                  )}
                  <div className="item-actions">
                    <FaEdit onClick={() => setEditingPublication(pub)} />
                    <FaTrash onClick={() => handleDeletePublication(pub.id)} />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="sidebar">
        <div className="add-category">
          <h4>Add a new category</h4>
          <input
            type="text"
            placeholder="Enter new category"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
          <textarea
            placeholder="Enter category description"
            value={newCategoryDescription}
            onChange={(e) => setNewCategoryDescription(e.target.value)}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="category-image-upload"
          />
          {newCategoryImage && (
            <img
              src={newCategoryImage}
              alt="New Category Preview"
              className="category-image-preview"
            />
          )}
          <button className="add-button" onClick={handleAddCategory}>
            ADD CATEGORY
          </button>
        </div>

        <div className="categories-list">
          <h3>Existing Categories</h3>
          {categories.map((cat) => (
            <div key={cat.id} className="list-item">
              {editingCategory?.id === cat.id ? (
                <div className="edit-form">
                  <input
                    value={editingCategory.name}
                    onChange={(e) =>
                      setEditingCategory({
                        ...editingCategory,
                        name: e.target.value,
                      })
                    }
                  />
                  <textarea
                    value={editingCategory.description}
                    onChange={(e) =>
                      setEditingCategory({
                        ...editingCategory,
                        description: e.target.value,
                      })
                    }
                  />
                  <div className="image-edit-container">
                    {editingCategory.image && (
                      <img
                        src={editingCategory.image}
                        alt="Current"
                        className="edit-image-preview"
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleEditCategoryImage}
                      className="edit-image-upload"
                    />
                  </div>
                  <button onClick={() => handleUpdateCategory(cat.id)}>
                    Save
                  </button>
                  <button onClick={() => setEditingCategory(null)}>
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="item-content">
                  <h4>{cat.name}</h4>
                  <p>{cat.description}</p>
                  {cat.image && (
                    <img
                      src={cat.image}
                      alt={cat.name}
                      className="item-image"
                    />
                  )}
                  <div className="item-actions">
                    <FaEdit onClick={() => setEditingCategory(cat)} />
                    <FaTrash onClick={() => handleDeleteCategory(cat.id)} />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPublications;
